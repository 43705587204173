<template>
  <b-form @submit="fm_submit">
     <div class="form-group activationform">
       <b-form-input type="text" :class="{ 'is-invalid':  $v.activationCode.$error }" id="fm_activation_code" v-model.trim="activationCode" @blur="$v.activationCode.$touch()" :placeholder="activationCodeMessage.ActivationCodePlaceholder" :aria-label="activationCodeMessage.ActivationCodePlaceholder" class="mb-3 rounded vgs-form-input"></b-form-input>
       <div v-if=" $v.activationCode.$error" class="invalid-feedback error-class">
        <span v-if="!$v.activationCode.required" class="float-left mb-2 mt-n2">{{ activationCodeMessage.ActivationCodeRequired }}</span>
      </div>
    </div>
    <div class="vgs-error-messages" v-html="get_error_messages"></div>
    <b-button
      type="submit"
      variant="primary"
      class="w-100 p-3 rounded vgs-form-button"
      :disabled="isdisabled"
      >{{activationCodeMessage.ActivationButtonText}}</b-button
    >
  </b-form>
</template>

<script>
import { vcm } from "@/mixins/vcm";
import { customLabels } from "@/mixins/customLabels";
import {mapActions} from "vuex";
import { required } from "vuelidate/lib/validators";
import { getUser } from '@/services/settingService';
export default {
  mixins: [vcm, customLabels],
  name: "AccountActivationForm",
  props: {},
  data() {
    return {
      isSuccess: false,
      activationCode: "",
      data: {},
      isdisabled : false
    };
  },
  validations: {
      activationCode: { required },
  },
  methods: {
    ...mapActions({
      activateCode : "setting/activateCodeAction"
    }),
    fm_submit(event) {
      event.preventDefault();
      this.isdisabled = true;
      this.$v.$touch();
      if (this.$v.activationCode.$invalid) {
        this.isdisabled = false;
        return;
      }
      let TypeName = 'ActivationCode';
      let Code = this.activationCode;
       // call to API forget API
      this.activateCode({TypeName, Code}).then((result) => {
        if(result.responseCode === '200' && result.responseMessage === 'Ok') {
          this.isSuccess = true;
          this.add_error_message(this.activationCodeMessage.SuccessMessage, this.isSuccess);
          this.$store.commit("setting/SET_ACTIVATION_STATE", true);
          setTimeout(() => {
            this.$root.goto("startpage");
          }, 2000); 
          this.isdisabled = false;
        } 
        else if(result.responseCode === '1034' || result.responseMessage === 'Failed Attempt Limit Exceeded'){
          this.add_error_message(this.activationCodeMessage.ErrorMessageLimitExceed, this.isSuccess);
          this.isdisabled = true;
        }
        else {
          console.log("result", result);
          this.add_error_message(this.activationCodeMessage.ErrorMessage, this.isSuccess);
          this.isdisabled = false;
        }
        getUser().then((result) => {
          if(result.responseCode === '200' && result.responseMessage === 'User data') {
            console.log("result", result.response.User.StateName);
            this.$store.commit("setting/SET_ACTIVATION_STATE", result.response.User.StateName ==='Complete' ? true : false);
          } 
        });
      })
    }
  }
};
</script>

<style scoped>
.activationform.form-group label, .form-group2 label{display: block;}
.activationform.form-group textarea{resize:none; height: 42px;}
.activationform{text-align: left;}
.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none !important;
}
</style>
