<template>
  <Page>
     <h1>{{Title}}</h1>
    <p>{{Description}}</p>
     <div class="activate" >
     <ActivationForm></ActivationForm >
    </div>
  </Page>
</template>

<script>
import Page from '@/structure/Page.vue'
import ActivationForm from '@/forms/AccountActivationForm.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import { getPageContent } from '@/services/pageContentService';
import { setUserState, getUser } from '@/services/settingService';
export default {
  mixins: [vcm,vvm],
  name: 'AccountActivation',
  components: {
    Page, ActivationForm
  },
  data() {
    return { 
      Title: null,
      Description: null
    }
  },
  async created() {
    console.log('account activation');
    let stateName = 'CodeEntry';
    getUser().then((result) => {
        if(result.responseCode === '200' && result.responseMessage === 'User data') {
          if(result.response.User.StateName === 'CodeEntry' || result.response.User.StateName === 'CodeDispatched'){
            setUserState({stateName}).then((result) => {
                if(result.responseCode === '200' && result.responseMessage === 'Ok') {
                  console.log('success');
                } else {
                  console.log("result", result);
                }
            })
          }
        }
    })
    let pageContent = await getPageContent('user-activation');
    if(pageContent.responseCode === '200') {
      this.Title  = pageContent.response.Title;
      this.Description  = pageContent.response.Description;
    }
  },
}
</script>

<style scoped>

h1 { font-size: 1.875rem; line-height: 2.563rem; color: #121212; }
p { font-size: 1rem; line-height: 1.625rem; color: #121212; text-align: center; }
.activate{margin-top: 10%;}
</style>

